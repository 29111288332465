import React, { useState, createRef, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, connect } from "react-redux"

function FirebaseFileUpload(props) {
    const dispatch = useDispatch()
    const [dragging, setDragging] = useState(false)
    const dropRef = createRef()
    var dragCounter = 0

    useEffect(() => {
        let div = dropRef.current
        div.addEventListener('dragenter', handleDragIn)
        div.addEventListener('dragleave', handleDragOut)
        div.addEventListener('dragover', handleDrag)
        div.addEventListener('drop', handleDrop)

        return () => {
            div.removeEventListener('dragenter', handleDragIn)
            div.removeEventListener('dragleave', handleDragOut)
            div.removeEventListener('dragover', handleDrag)
            div.removeEventListener('drop', handleDrop)
        }
    }, [])

    const handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const handleDragIn = (e) => {
        e.preventDefault()
        e.stopPropagation()
        dragCounter++
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            setDragging(true)
        }
    }

    const handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()
        dragCounter--
        if (dragCounter > 0) return
        setDragging(false)
    }

    const handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setDragging(false)
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleFiles(e.dataTransfer.files)
            e.dataTransfer.clearData()
            dragCounter = 0
        }
    }

    const handleFiles = (data) => {
        dispatch({ type: 'ADD_IMAGES', payload: data})
    }

    return (
        <DnD ref={dropRef}>
            <Container dragging={dragging}>
                {props.images.length === 0 ?
                    <Span>slipp</Span>
                    :
                    <>
                        {
                            props.images.map((image, i) =>
                                <div key={i}>{image.name}</div>
                            )
                        }
                    </>
                }
            </Container>
        </DnD>
    )
}

const DnD = styled.div`
    margin-top: 50px;
    position: relative;
    display: inline-block;
    width: 100%;
`

const Container = styled.div`
    position: relative;
    min-height: 190px;
    border: solid #D34E38 2px;
    background-color: rgba(255,255,255,.8);
    
    ${({ dragging }) => dragging && `
        border: dashed #2249AA 2px;
    `}
`

const Span = styled.span`
    position: absolute;
    font-size: 16pt;
    font-weight: 600;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
`

function mapStateToProps(state) {
    return {
        images: state.images,
    }
}

export default connect(mapStateToProps)(FirebaseFileUpload)