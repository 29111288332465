import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import BackButton from "../components/BackButton"
import { useHistory } from 'react-router-dom'
import FieldSet from '../components/FieldSet'
import Loader from '../components/Loader'
import { getOneProject, addComment, addRating, checkUser } from '../fetchApi'
import { useDispatch, connect } from "react-redux"
import { storage } from '../firebase'

// Kanskje bruke firebase for å lagre bilder i stedet for DB
// https://www.youtube.com/watch?v=8r1Pb6Ja90o&ab_channel=HongLy

function Project(props) {
    const { projectId } = useParams()
    const dispatch = useDispatch()
    const history = useHistory()
    const [fieldValue, setFieldValue] = useState("")
    const [rating, setRating] = useState(undefined)
    const [avgRating, setAvgRating] = useState(null)
    const [comments, setComments] = useState()
    const [images, setImages] = useState([])
    const [project, setProject] = useState({
        name: '',
        link: '',
        attributes: [],
        active: false,
    })

    useEffect(() => {
        checkUser().then().catch(_ => {
			localStorage.clear()
            history.push('/login')
		})
        dispatch({ type: 'SET_ACTIVE_PROJECT', payload: projectId })

        let imageObject = props.displayImages.filter(i => i.name === projectId)[0]
        if (imageObject) {
            setImages([imageObject.url])
        }

        storage
            .ref(`images/projects/${projectId}`)
            .listAll()
            .then((result) => {
                result.items.forEach((imageRef) => {
                    displayImage(imageRef);
                });
            }).catch((error) => {
                console.log(error)
            });

        getOneProject(projectId)
            .then(response => {
                let projectData = response.project
                let materials = projectData.material.split(',').map(m => m.trim().toLowerCase())
                let rating = response.user_rating
                setProject({
                    name: projectData.name,
                    link: projectData.link,
                    attributes: [
                        projectData.architect,
                        projectData.location,
                        projectData.year,
                        ...materials,
                        projectData.size,
                    ].filter(a => a !== ""),
                    active: true
                })
                setAvgRating(projectData.glrate)
                setComments(response.comments)
                if (rating) {
                    setRating(rating)
                }
            })
            .catch(err => {
                console.log(err)
            })
        return () => {
            dispatch({ type: 'SET_ACTIVE_PROJECT', payload: null })
        }
    }, [])

    const displayImage = (imageRef) => {
        imageRef.getDownloadURL().then((url) => {
            setImages(images => [...images, url])
        }).catch((error) => {
            console.log(error)
        });
    }

    const handleInputChange = (e) => {
        setFieldValue(e.target.value)
    }

    const handleRatingChange = (e) => {
        let num = parseInt(e.target.value)
        if (num < 1) {
            setRating(1)
        } else if (num > 10) {
            setRating(10)
        } else {
            setRating(e.target.value)
        }
    }

    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            addComment({ project_id: projectId, text: fieldValue })
                .then(response => setComments(response.comments))
                .catch(err => {
                    console.log(err)
                })
            setFieldValue("")
        }
    }

    const handleRatingEnter = (e) => {
        if (e.key === 'Enter') {
            addRating({ project_id: projectId, rating: rating })
                .then(response => {
                    setAvgRating(response.newRating)
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    const handleEditClick = () => {
        history.push(`/edit/${projectId}`)
    }

    const hasImages = images?.length > 0

    if (!project.active) {
        return (
            <Wrapper>
                <Loader />
            </Wrapper>
        )
    }

    return (
        <Wrapper>
            <Title>
                <Link href={project.link}>
                    {project.name}
                </Link>
            </Title>
            <SubText>
                {project.attributes.map((a, i) => {
                    let endString = i === project.attributes.length - 1 ? "" : ", "
                    return <span key={i}>{a}{endString}</span>
                })}
            </SubText>
            <RatingContainer>
                <AvgRating>
                    {Math.round(avgRating * 10) / 10}
                </AvgRating>
                <YourRating
                    id="number"
                    type="number"
                    value={rating}
                    min="1"
                    max="10"
                    onChange={handleRatingChange}
                    onKeyDown={handleRatingEnter} />
            </RatingContainer>
            {hasImages && <ImageContainer>
                {images.map((img, index) => (
                    <ImageWrapper>
                        <Image key={index} src={img} />
                    </ImageWrapper>
                ))}
            </ImageContainer>}
            {!hasImages &&
                <NoImages>
                    Dette prosjektet har ingen bilder (enda...)
                </NoImages>
            }
            <CommentContainer>
                {comments && comments.map((c, k) => (
                    <CommentRow key={k}>
                        <From>
                            {c.from + ":"}
                        </From>
                        <Comment>
                            {"\"" + c.text + "\""}
                        </Comment>
                    </CommentRow>
                ))}
            </CommentContainer>
            <FieldSetContainer>
                <FieldSet
                    type="text"
                    name="project"
                    handleInputChange={handleInputChange}
                    handleKeyDown={handleEnter}
                    value={fieldValue}
                />
            </FieldSetContainer>
            <EditButton onClick={handleEditClick}>
                rediger prosjektet
            </EditButton>
            <BackButton handleClose={() => history.push('/dashboard')} />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 150px;
`

const Title = styled.div`
    display: inline-block;
    font-size: 30pt;
`

const Link = styled.a`
    color: inherit;
    text-decoration: none;
`

const SubText = styled.div`
    display: inline-block;
    font-size: 16pt;
    font-style: italic;
    max-width: 40%;
`

const RatingContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 5rem;
    margin-top: 2rem;
`

const AvgRating = styled.button`
    height: 2rem;
    width: 2rem; 
    border: none;
    background-color: #D34E38;
    color: #2249AA;
    font-size: 14pt;
    padding: 0;
`

const YourRating = styled.input`
    text-align: center;
    height: 2rem;
    width: 2rem;
    justify-content: center;
    border: none;
    color: #D34E38;
    background-color: #2249AA;
    font-size: 14pt;
    box-sizing: border-box;
    -moz-appearance:textfield;

    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
`

const ImageContainer = styled.div`
    margin-top: 8rem;
    padding: 0 150px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 15vh;
    grid-column-gap: 20vw;
    justify-content: space-between;
    align-content: space-around;
    margin-bottom: 100px;
`

const ImageWrapper = styled.div`
    min-height: 350px;
`

const Image = styled.img`
    max-width: 350px;
`

const CommentContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 25rem;
`

const CommentRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 2rem;
`

const From = styled.div`
    flex: 0 0 20%;
    font-weight: 600;
    font-style: italic;
`

const Comment = styled.div`
    flex: 1;
    font-weight: 600;
    font-style: italic;
    color: #2249AA;
`

const EditButton = styled.button`
    margin: 100px auto 50px auto;
    background: none;
    border: none;
    font-size: 16pt;
    font-weight: 500;
    color: #2249AA;
    cursor: pointer;

    &:hover {
        font-style: italic;
    }
`

const FieldSetContainer = styled.div`
    width: 25rem;
`

const NoImages = styled.div`
    padding: 50px 0;
    font-size: 16pt;
    font-style: italic;
    color: #2249AA;
`

function mapStateToProps(state) {
    return {
        displayImages: state.displayImages
    }
}

export default connect(mapStateToProps)(Project)
