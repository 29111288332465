import './App.css'
import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { BrowserRouter, Route, } from "react-router-dom"
import Landing from './pages/Landing'
import LoginPage from './pages/Login'
import Dashboard from './pages/Dashboard'
import Project from './pages/Project'
import AddProject from './pages/AddProject'
import EditProject from './pages/EditProject'
import Div from './pages/Div'
import { getAllProjects, getAllDivs } from './fetchApi'
import { storage } from './firebase'

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    getAllProjects()
      .then(response => {
        dispatch({ type: 'ADD_ARCHITECTURES', payload: response.data })
      })
      .catch(err => {
        console.log(err)
      })
    getAllDivs()
      .then(response => {
        dispatch({ type: 'ADD_DIVS', payload: response.data })
      })
      .catch(err => {
        console.log(err)
      })
    getDisplayImages('dashboard')
    getDisplayImages('dashboard_divs', true)
  }, [])

  const getDisplayImages = (location, divs=false) => {
    storage
      .ref(`images/${location}`)
      .listAll()
      .then((result) => {
        result.items.forEach((imageRef) => {
          storeImage(imageRef, divs);
        });
      }).catch((error) => {
        console.log(error)
      });
  }

  const storeImage = (imageRef, divs) => {
    let reduxType = divs ? 'ADD_DIVS_IMAGES' : 'ADD_DISPLAY_IMAGES'
    let fileName = storage.refFromURL(imageRef).name;
    imageRef.getDownloadURL().then((url) => {
      dispatch({ type: reduxType, payload: {
        name: fileName,
        url: url
      } })
    }).catch((error) => {
      console.log(error)
    });
  }

  return (
    <BrowserRouter>
      <Route exact path='/' component={Landing} />
      <Route path='/login' component={LoginPage} />
      <Route path='/dashboard' component={Dashboard} />
      <Route path='/add' component={AddProject} />
      <Route path='/edit/:projectId' component={EditProject} />
      <Route path='/project/:projectId' component={Project} />
      <Route path='/div/:divId' component={Div} />
    </BrowserRouter>
  )
}

export default App;