import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import BackButton from "../components/BackButton"
import { useHistory } from 'react-router-dom'
import { useDispatch, connect } from "react-redux"
import Loader from '../components/Loader'
import { deleteDiv, getAllDivs, checkUser } from '../fetchApi'
import { storage } from '../firebase'

function Project(props) {
    const { divId } = useParams()
    const history = useHistory()
    const dispatch = useDispatch()
    const [images, setImages] = useState([])
    const [div, setDiv] = useState({
        name: '',
        link: '',
        images: []
    })

    useEffect(() => {
        checkUser().then().catch(_ => {
			localStorage.clear()
            history.push('/login')
		})
        let imageObject = props.divsImages.filter(i => i.name === divId)[0]
        if (imageObject) {
            setImages([imageObject.url])
        }

        storage
            .ref(`images/divs/${divId}`)
            .listAll()
            .then((result) => {
                result.items.forEach((imageRef) => {
                    displayImage(imageRef);
                });
            }).catch((error) => {
                console.log(error)
            });
        const divs = props.divs
        if (divs?.length !== 0) {
            let div = props.divs.filter(d => d.id === parseInt(divId))[0]
            setDiv({
                name: div?.what,
                link: div?.link,
                images: []
            })
        }
    }, [props])

    const displayImage = (imageRef) => {
        imageRef.getDownloadURL().then((url) => {
            setImages(images => [...images, url])
        }).catch((error) => {
            console.log(error)
        });
    }

    const handleDelete = () => {
        var r = window.confirm("Er du sikker på at du vil slette denne diven?");
        if (r == true) {
            deleteDiv({
                id: divId
            })
                .then(() => {
                    getAllDivs()
                        .then(response => {
                            dispatch({ type: 'ADD_DIVS', payload: response.data })
                        })
                        .then(() => {
                            storage
                                .ref(`images/divs/${divId}`)
                                .listAll()
                                .then((result) => {
                                    result.items.forEach((imageRef) => {
                                        imageRef.delete()
                                    });
                                }).catch((error) => {
                                    console.log(error)
                                });
                            storage.ref(`images/dashboard_divs/${divId}`).delete()
                        })
                        .then(() => {
                            window.alert('Div slettet')
                            history.push('/dashboard')
                        })
                        .catch(err => {
                            console.log(err)
                        })
                })
                .catch(err => console.log(err))
        }
    }

    const hasImages = images.length > 0

    if (!div) {
        return (
            <Wrapper>
                <Loader />
            </Wrapper>
        )
    }

    return (
        <Wrapper>
            <Title>
                <Link href={div.link}>
                    {div.name}
                </Link>
            </Title>
            {hasImages && <ImageContainer>
                {images.map((img, index) => (
                    <ImageWrapper>
                        <Image key={index} src={img} />
                    </ImageWrapper>
                ))}
            </ImageContainer>}
            {!hasImages &&
                <NoImages>
                    Her er det ingen bilder (enda...)
                </NoImages>
            }
            <ButtonRow>
                <FormButton onClick={handleDelete}>
                    <Icon src={'/symboler-16.png'} />
                </FormButton>
            </ButtonRow>
            <BackButton handleClose={() => history.push('/dashboard')} />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 150px;
`

const Title = styled.div`
    display: inline-block;
    font-size: 30pt;
`

const Link = styled.a`
    color: inherit;
    text-decoration: none;
`

const ImageContainer = styled.div`
    margin-top: 8rem;
    padding: 0 150px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 15vh;
    grid-column-gap: 20vw;
    justify-content: space-between;
    align-content: space-around;
    margin-bottom: 100px;
`

const ImageWrapper = styled.div`
    height: 350px;
    width: 350px;
`

const Image = styled.img`
    max-width: 350px;
`

const NoImages = styled.div`
    padding: 50px 0;
    font-size: 16pt;
    font-style: italic;
    color: #2249AA;
`

const ButtonRow = styled.div`
    margin-bottom: 50px;
`

const FormButton = styled.button`
    border: none;
    background: transparent;
`

const Icon = styled.img`
    width: 3rem;
    height: auto;
    cursor: pointer;
`

function mapStateToProps(state) {
    return {
        divs: state.divs,
        divsImages: state.divsImages,
    }
}

export default connect(mapStateToProps)(Project)