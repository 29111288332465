import { useEffect, useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import BackButton from '../components/BackButton'
import FieldSet from '../components/FieldSet'
import { login, checkUser } from '../fetchApi'

function formReducer(state, action) {
	switch (action.type) {
		case 'add':
			return {
				...state,
				[action.name]: action.value
			}
	}
}

function LoginPage() {
	const [formData, dispatch] = useReducer(formReducer, {});
	const history = useHistory()

	useEffect(() => {
		if (localStorage.getItem('sessionToken')) {
			checkUser().then((response) => {
				console.log(response)
				history.push('/dashboard')
			})
			.catch((err) => {
				console.log(err)
				// localStorage.clear()
			})
		}
	}, [])

	const handleInputChange = (e) => {
		dispatch({ type: 'add', name: e.target.name, value: e.target.value })
	}

	const handleEnter = (e) => {
		if (e.key === 'Enter') {
			login({ email: formData.id, password: formData.code }).then(response => {
				if (response.token) {
					localStorage.setItem('sessionToken', response.token)
				}
			})
				.then(() => history.push('/dashboard'))
				.catch(err => {
					console.log(err)
				})
		}
	}

	return (
		<Wrapper>
			<LoginContainer>
				<FieldSetContainer>
					<FieldSet
						type="password"
						name="id"
						fieldText="ID: "
						handleInputChange={handleInputChange}
						handleKeyDown={handleEnter}
						value={formData.id}
					/>
					<FieldSet
						type="password"
						name="code"
						fieldText="KODE: "
						handleInputChange={handleInputChange}
						handleKeyDown={handleEnter}
						value={formData.code}
					/>
				</FieldSetContainer>
			</LoginContainer>
			<BackButton handleClose={() => history.push('/')} />
		</Wrapper>
	)
}

const Wrapper = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const LoginContainer = styled.div`
    width: 550px;
    display: flex;
    flex-direction: column;
    border: 4px solid #D34E38;
`

const FieldSetContainer = styled.div`
	padding: 4rem 0.5rem 4rem 2rem;
`

/* const FieldSet = styled.fieldset`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
  border: none;
`

const InputField = styled.input`
  border: none;
  border-bottom: 1px solid #D34E38;
	font-size: 24pt;
  font-weight: 500;
	color: #D34E38;

	&:focus {
		outline: none;
	}
`

const FieldText = styled.label`
	font-size: 24pt;
  font-weight: 500;
	color: #D34E38;
` */

export default LoginPage