import { useState } from "react"
import { useDispatch, connect } from "react-redux"
import styled, { css } from "styled-components"

const tabs = {
    0: 'arkitektur',
    1: 'div',
}

function FaceTabs(props) {
    const dispatch = useDispatch()

    const handleTabClick = (t) => {
        dispatch({ type: 'SET_ACTIVE_FACE_TAB', payload: t })
    }

    return (
        <Wrapper>
            <TabContainer>
                {Object.keys(tabs).map((t, i) => (
                    <Button key={i} active={t === props.activeFaceTab} onClick={() => handleTabClick(t)}>{tabs[t]}</Button>
                ))}
            </TabContainer>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    z-index: 2;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 70px;
`

const TabContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`

const Button = styled.button`
    border: none;
    background-color: white;
    font-size: 20pt;
    font-family: Roboto;
    font-weight: 500;
    cursor: pointer;
    color: #2249AA;

    //background: linear-gradient(to bottom, #D34E38 2px, transparent 2px) 0 0, linear-gradient(to right, #D34E38 2px, transparent 2px) 0 100%, linear-gradient(to left, #D34E38 2px, transparent 2px) 100% 0, linear-gradient(to top, #D34E38 2px, transparent 2px) 100% 100%;
    background: linear-gradient(to right, #D34E38 2px, transparent 2px) 0 100%;
    background-repeat: no-repeat;
    background-size: 0px 0px;
    transition: background-size 0.5s cubic-bezier(0, 1.5, 1, 1.5);

    &:hover {
        background-size: 100% 100%;
    }
    
    ${({ active }) => active && `
        color: #D34E38;
    `} 
`
function mapStateToProps(state) {
    return {
        activeFaceTab: state.activeFaceTab,
    }
}

export default connect(mapStateToProps)(FaceTabs)