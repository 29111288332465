import { useEffect, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'
import QuoteModal from '../components/QuoteModal'
import { Link } from 'react-router-dom'
import { getNumberOfProjects } from '../fetchApi'
import Loader from '../components/Loader'

const len = 16
const val = "0000000000000000"

function Landing() {
    const [numOfProjects, setNumOfProjects] = useState("")
    const [quoteOpen, setQuoteOpen] = useState(false)

    useEffect(() => {
        let num = 0
        getNumberOfProjects()
            .then(response => {
                num = response.length
                let numStr = num.toString()
                setNumOfProjects(val.slice(0, len - numStr.length) + numStr)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    const toggleQuote = () => {
        setQuoteOpen(!quoteOpen)
    }

    return (
        <Wrapper behind={quoteOpen}>
            <MainContainer behind={quoteOpen}>
                <BigContainer>
                    <Big>
                        BANK
                    </Big>
                    <Big>
                        a la FPGL
                    </Big>
                </BigContainer>
                <SmallContainer>
                    <MediumRow>
                        <Medium>ET</Medium>
                        <ItalicsBlue>EFFEPECHELLE</ItalicsBlue>
                    </MediumRow>
                    <Medium>
                        SAMARBEID
                    </Medium>
                </SmallContainer>
                <BigContainer>
                    {numOfProjects.length === 0
                        ?
                        <Loader isSmall={true} />
                        :
                        <Medium>
                            {numOfProjects}
                        </Medium>
                    }
                </BigContainer>
                <Bottom>
                    <BorderRight>
                        <BigContainer bottom={true}>
                            <CustomLink to="/login">
                                <Medium>
                                    LOGG INN
                                </Medium>
                            </CustomLink>
                        </BigContainer>
                    </BorderRight>
                    <ButtonContainer>
                        <Button onClick={toggleQuote}>
                            <Medium>
                                ?
                            </Medium>
                        </Button>
                    </ButtonContainer>
                </Bottom>
            </MainContainer>
            {quoteOpen &&
                <QuoteModal handleClose={toggleQuote} />
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
`

const MainContainer = styled.div`
    width: 550px;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    border: 4px solid #D34E38;
    transition: opacity 100ms linear;
    opacity: ${props => props.behind ? 0.5 : 1};
`

const BigContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-bottom: 1px solid #D34E38;

    ${({ bottom }) => bottom && css`
		border: none;
	`}
`

const SmallContainer = styled(BigContainer)`
    padding-top: 1rem;
    padding-bottom: 1rem;
`

const MediumRow = styled.div`
    display: flex;
    flex-direction: row;
`

const Bottom = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
`

const CustomLink = styled(Link)`
    text-decoration: none;

    &:hover {
        font-style: italic;
    }
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
`

const Button = styled.button`
    border: none;
    background-color: white;
    font-size: 30pt;
    font-weight: bold;
    font-family: 'Roboto';
    color: #D34E38;

    &:hover {
        font-style: italic;
    }
`

const BorderRight = styled.div`
    border-right: 1px solid #D34E38;
`

const Big = styled.span`
    font-size: 60pt;
    font-weight: bold;
    color: #D34E38;
`

const Medium = styled.span`
    font-size: 30pt;
    font-weight: bold;
    color: #D34E38;
`

const ItalicsBlue = styled.span`
    margin-left: .5rem;
    font-size: 30pt;
    font-style: italic;
    font-weight: 500;
    color: #2249AA;
`

export default Landing