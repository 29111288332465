import { useEffect, useReducer } from "react"
import BackButton from "../components/BackButton"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { useHistory } from 'react-router-dom'
import styled from "styled-components"
import { getOneProject, editProject, deleteProject, getAllProjects, checkUser } from '../fetchApi'
import { storage } from '../firebase'


const fields = [
    "Prosjekt",
    "Link",
    "Arkitekt",
    "År",
    "Sted",
    "Størrelse",
    "Materialer"
]

const initialState = {
    Prosjekt: "",
    Link: "",
    Arkitekt: "",
    År: "",
    Sted: "",
    Størrelse: "",
    Materialer: "",
}

function formReducer(state, action) {
    switch (action.type) {
        case 'formChange':
            return {
                ...state,
                [action.field]: action.payload
            }
        case 'setState':
            return action.payload
        default:
            throw new Error();
    }
}

function EditProject(props) {
    const dispatch = useDispatch()
    const { projectId } = useParams()
    const [formState, formDispatch] = useReducer(formReducer, initialState)
    const history = useHistory()

    useEffect(() => {
        checkUser().then().catch(_ => {
			localStorage.clear()
            history.push('/login')
		})
        getOneProject(projectId)
            .then(response => {
                let projectData = response.project
                let materials = projectData.material.split(',').map(m => m.trim()) + ""
                formDispatch({
                    type: 'setState',
                    payload: {
                        Prosjekt: projectData.name,
                        Link: projectData.link,
                        Arkitekt: projectData.architect,
                        År: projectData.year,
                        Sted: projectData.location,
                        Størrelse: projectData.size,
                        Materialer: materials
                    }
                })
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    const handleFormChange = (f, e) => {
        formDispatch({ type: 'formChange', field: f, payload: e.target.value })
    }

    const handleFormSubmit = () => {
        editProject({
            id: projectId,
            name: formState.Prosjekt,
            link: formState.Link,
            architect: formState.Arkitekt,
            year: formState.År,
            location: formState.Sted,
            size: formState.Størrelse,
            material: formState.Materialer.toLowerCase()
        })
            .then(response => {
                dispatch({ type: 'ADD_ARCHITECTURES', payload: response.data })
            })
            .then(response => {
                history.push(`/project/${projectId}`)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleBack = () => {
        history.push(`/project/${projectId}`)
    }

    const handleDelete = () => {
        var r = window.confirm("Er du sikker på at du vil slette dette prosjektet?");
        if (r == true) {
            deleteProject({
                id: projectId
            })
                .then(() => {
                    getAllProjects()
                        .then(response => {
                            dispatch({ type: 'ADD_ARCHITECTURES', payload: response.data })
                        })
                        .then(() => {
                            storage
                                .ref(`images/projects/${projectId}`)
                                .listAll()
                                .then((result) => {
                                    result.items.forEach((imageRef) => {
                                        imageRef.delete()
                                    });
                                }).catch((error) => {
                                    console.log(error)
                                });
                            storage.ref(`images/dashboard/${projectId}`).delete()
                        })
                        .then(() => {
                            window.alert('Prosjekt slettet')
                            history.push('/dashboard')
                        })
                        .catch(err => {
                            console.log(err)
                        })
                })
                .catch(err => console.log(err))
        }
    }

    return (
        <Wrapper>
            <TabsContainer>
                <Title>
                    Rediger prosjekt
                </Title>
            </TabsContainer>
            <Form autocomplete="off">
                {fields.map((f, i) => (
                    <>
                        <Label key={i} htmlFor={f}>
                            {f + ":"}
                        </Label>
                        {f === 'Størrelse' ?
                            <Select id="sizes" name="sizes" onChange={(e) => handleFormChange(f, e)}>
                                <Option disabled selected={formState['Størrelse'] == ""} value hidden></Option>
                                <Option selected={formState['Størrelse'] == "XS"} value="XS">XS</Option>
                                <Option selected={formState['Størrelse'] == "S"} value="S">S</Option>
                                <Option selected={formState['Størrelse'] == "M"} value="M">M</Option>
                                <Option selected={formState['Størrelse'] == "L"} value="L">L</Option>
                                <Option selected={formState['Størrelse'] == "XL"} value="XL">XL</Option>
                            </Select>
                            :
                            <Text
                                type="text"
                                name={f}
                                value={formState[f]}
                                onChange={(e) => handleFormChange(f, e)} />
                        }
                    </>
                ))}
            </Form>
            <ButtonRow>
                <FormButton onClick={handleFormSubmit}>
                    <Icon src={'/symboler-15.png'} />
                </FormButton>
                <FormButton onClick={handleDelete}>
                    <Icon src={'/symboler-16.png'} />
                </FormButton>
            </ButtonRow>
            <BackButton handleClose={handleBack} />
        </Wrapper >
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 20%;
`

const TabsContainer = styled.div`
    width: 330px;
    margin-bottom: 75px;
    text-align: center;
`

const Title = styled.div`
    display: inline-block;
    font-size: 30pt;
`

const Form = styled.form`
    align-self: flex-start;
    display: grid;
    grid-template-columns: 1fr 3fr;
    width: 100%;
`

const Label = styled.label`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    //align-items: center;
    gap: 5rem;
    font-size: 20pt;
`

const Text = styled.input`
    outline: none;
    border: none;
    margin-left: 4rem;
    color: #D34E38;
    font-size: 20pt;

    background: linear-gradient(to top, #2249AA 2px, transparent 2px) 0 100%;
    background-repeat: no-repeat;
    background-size: 0px 0px;
    transition: background-size 1s cubic-bezier(0, 1.5, 1, 1.5);

    &:hover {
        background-size: 100% 100%;
    }
`

const Select = styled.select`
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    border: none;
    margin-left: 4rem;
    color: #D34E38;
    font-size: 20pt;

    background: linear-gradient(to top, #2249AA 2px, transparent 2px) 0 100%;
    background-repeat: no-repeat;
    background-size: 0px 0px;
    transition: background-size 1s cubic-bezier(0, 1.5, 1, 1.5);

    &:hover {
        background-size: 100% 100%;
    }
`

const Option = styled.option`

`

const TextArea = styled.textarea`
    outline: none;
    border: none;
    height: 15vh;
    resize: vertical;
    margin-left: 4rem;
    color: #D34E38;
    font-size: 20pt;
    font-family: Roboto;

    background: linear-gradient(to bottom, #2249AA 2px, transparent 2px) 0 0, linear-gradient(to right, #2249AA 2px, transparent 2px) 0 100%, linear-gradient(to left, #2249AA 2px, transparent 2px) 100% 0, linear-gradient(to top, #2249AA 2px, transparent 2px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 0px 0px;
    transition: background-size 1s cubic-bezier(0, 1.5, 1, 1.5);

    &:hover {
        background-size: 100% 100%;
    }
`

const ButtonRow = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 105px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`

const FormButton = styled.button`
    border: none;
    background: transparent;
`

const Icon = styled.img`
    width: 3rem;
    height: auto;
    cursor: pointer;
`

export default EditProject