import { connect } from "react-redux"
import SortingTab from './SortingTab'
import ProjectTab from './ProjectTab'
import styled, { css } from "styled-components"

function Tabs(props) {
    const activeTab = props.isDashboard ? props.activeTab : props.activeProjectTab

    return (
        <Wrapper>
            <TabContainer isProject={props.isProject}>
                {props.tabs.map((t, i) => (
                    <div key={i}>
                        {t === activeTab &&
                            <Halo label={activeTab} />
                        }
                        {props.isDashboard && <SortingTab name={t} />}
                        {props.isProject && <ProjectTab name={t} />}
                    </div>
                ))}
            </TabContainer>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    z-index: 2;
    padding: 0 1rem;
`

const TabContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 2.25rem;
    height: 70px;

    ${({ isProject }) => isProject && css`
        padding: 0;
    `
    }
`
const Halo = styled.div`
    position: absolute;
    margin-top: 6px;
    pointer-events: none;

    ${({ label }) => label === 'Materiale' && css`
        margin-left: -5px;
        width: 130px;
        height: 23px;
        border: 2px solid #2249AA;
        border-radius: 130px / 28px;
        `
        || label.length >= 8 && css`
        margin-left: -10px;
        width: 130px;
        height: 23px;
        border: 2px solid #2249AA;
        border-radius: 130px / 28px;
        ` 
        || label.length >= 3 && css`
        margin-left: -10px;
        width: 75px;
        height: 23px;
        border: 2px solid #2249AA;
        border-radius: 75px / 25px;
        `
        || (label === 'År' || label === 'GL') && css`
        margin-left: -10px;
        width: 50px;
        height: 23px;
        border: 2px solid #2249AA;
        border-radius: 50px / 25px;
        `
    } 
`

function mapStateToProps(state) {
    return {
        activeTab: state.activeTab,
        activeProjectTab: state.activeProjectTab,
    }
}

export default connect(mapStateToProps)(Tabs)