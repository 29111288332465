import styled, { css, keyframes } from 'styled-components'
import BackButton from './BackButton'

const quote = "\"The contributors are asked to share their main sources of inspiration or references they simply enjoy at a specific moment in time.-Together the projects form a valuable database and a source for deeper discussion and a constant learning process.\""

function QuoteModal(props) {
    console.log(quote.split("-"))
    return (
        <PopupBox>
            <Box>
                <TextBox>
                    {quote.split("-").map((text, i) => (
                        <div key={i}>
                            <Medium>
                                {text}
                            </Medium>
                            <br/>
                        </div>
                    ))}
                </TextBox>
            </Box>
            <BackButton handleClose={props.handleClose} />
        </PopupBox>
    )
}

const PopupBox = styled.div`
    position: absolute;
    backdrop-filter: blur(0.7rem);
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
`

const Box = styled.div`
    position: relative;
    width: 500px;
    display: flex;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 4rem;
`

const TextBox = styled.div`
    display: flex;
    flex-direction: column;
`

const Medium = styled.h1`
    font-size: 24pt;
    font-weight: 600;
    font-style: italic;
    color: #D34E38;
`


export default QuoteModal