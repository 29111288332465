import { useEffect, useReducer } from "react"
import BackButton from "../components/BackButton";
import { useDispatch, connect } from "react-redux"
import { useHistory } from 'react-router-dom'
import styled from "styled-components";
import Tabs from "../components/Tabs";
import FileUpload from "../components/FileUpload";
import { addComment, addProject, addDiv, addQuote, checkUser } from '../fetchApi'
import { storage } from '../firebase'


const tabs = [
    "sitat",
    "prosjekt",
    "div"
]

const fields = [
    "Prosjekt",
    "Link",
    "Arkitekt",
    "År",
    "Sted",
    "Størrelse",
    "Materialer"
]

const initialState = {
    Prosjekt: "",
    Link: "",
    Arkitekt: "",
    År: "",
    Sted: "",
    Størrelse: "",
    Materialer: "",
    kommentar: "",
    sitat: "",
    sagtav: "",
    hva: "",
    linkdiv: "",
}

function formReducer(state, action) {
    switch (action.type) {
        case 'formChange':
            return {
                ...state,
                [action.field]: action.payload
            }
        default:
            throw new Error();
    }
}

function AddProject(props) {
    const dispatch = useDispatch()
    const [formState, formDispatch] = useReducer(formReducer, initialState)
    const history = useHistory()
    var uploadCounter = 0

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        checkUser().then().catch(_ => {
            localStorage.clear()
            history.push('/login')
        })
        dispatch({ type: 'SET_ACTIVE_PROJECT_TAB', payload: 'prosjekt' })
    }, [])

    const handleFormChange = (f, e) => {
        formDispatch({ type: 'formChange', field: f, payload: e.target.value })
    }

    const handleFormSubmit = () => {
        switch (props.activeProjectTab) {
            case 'prosjekt':
                addProject({
                    name: formState.Prosjekt,
                    link: formState.Link,
                    architect: formState.Arkitekt,
                    year: formState.År,
                    location: formState.Sted,
                    size: formState.Størrelse,
                    material: formState.Materialer.toLowerCase()
                })
                    .then(response => {
                        for (let i of props.images) {
                            handleUpload(response.project_id, i)
                        }
                        if (formState.kommentar.length !== 0) {
                            addComment({ project_id: response.project_id, text: formState.kommentar })
                                .then(() => {
                                    dispatch({ type: 'ADD_ARCHITECTURES', payload: response.data })
                                })
                        } else {
                            dispatch({ type: 'ADD_ARCHITECTURES', payload: response.data })
                        }
                    })
                    .then(() => {
                        window.alert('Prosjekt lagt til')
                        history.push('/dashboard')
                    })
                    .catch(() => {
                        window.alert('Kunne ikke legge til prosjekt')
                    })
                break
            case 'sitat':
                addQuote({
                    quote: formState.sitat,
                    by: formState.sagtav
                })
                    .then(response => {
                        window.alert('Sitat lagt til')
                        history.push('/dashboard')
                    })
                    .catch(err => window.alert('Kunne ikke legge til sitat'))
                break
            case 'div':
                addDiv({
                    what: formState.hva,
                    link: formState.linkdiv
                })
                    .then(response => {
                        for (let i of props.images) {
                            handleUpload(response.div_id, i, true)
                        }
                        dispatch({ type: 'ADD_DIVS', payload: response.data })
                    })
                    .then(() => {
                        window.alert('Div lagt til')
                        history.push('/dashboard')
                    })
                    .catch(err => window.alert('Kunne ikke legge til div'))
                break
            default:
                console.error("Something that was not supposed to happen happened")
        }
    }

    const handleUpload = (id, image, div = false) => {
        let reduxType = div ? 'ADD_DIVS_IMAGES' : 'ADD_DISPLAY_IMAGES'
        let locationFirst = div ? 'dashboard_divs' : 'dashboard'
        let location = div ? 'divs' : 'projects'
        let uploadRefFirst = `images/${locationFirst}/${id}`
        let uploadRef = `images/${location}/${id}/${image.name}`
        return new Promise((resolve, reject) => {
            if (uploadCounter == 0) {
                const uplaodTask = storage.ref(uploadRefFirst).put(image)
                uplaodTask.on(
                    'state_changed',
                    snapshot => { },
                    error => {
                        console.log(error)
                    },
                    () => {
                        storage
                            .ref(uploadRefFirst)
                            .getDownloadURL()
                            .then(url => {
                                dispatch({
                                    type: reduxType, payload: {
                                        name: "" + id,
                                        url: url
                                    }
                                })
                            })
                    }
                )
            } else {
                storage.ref(uploadRef).put(image)
            }
            uploadCounter++
        })
    }

    const handleFormCancel = () => {
        history.push('/dashboard')
    }

    return (
        <Wrapper>
            <TabsContainer>
                <Tabs isProject tabs={tabs} />
            </TabsContainer>
            {props.activeProjectTab === "prosjekt" &&
                <Form autocomplete="off">
                    {fields.map((f, i) => (
                        <>
                            <Label key={i} htmlFor={f}>
                                {f + ":"}
                            </Label>
                            {f === 'Størrelse' ?
                                <Select id="sizes" name="sizes" onChange={(e) => handleFormChange(f, e)}>
                                    <option hidden disabled selected value></option>
                                    <Option value="XS">XS</Option>
                                    <Option value="S">S</Option>
                                    <Option value="M">M</Option>
                                    <Option value="L">L</Option>
                                    <Option value="XL">XL</Option>
                                </Select>
                                :
                                <Text
                                    type="text"
                                    name={f}
                                    value={formState[f]}
                                    onChange={(e) => handleFormChange(f, e)} />
                            }
                        </>
                    ))}
                    <Label htmlFor="kommentar">
                        Kommentar:
                    </Label>
                    <TextArea
                        name="kommentar"
                        value={formState["kommentar"]}
                        onChange={(e) => handleFormChange("kommentar", e)} />
                </Form>
            }
            {props.activeProjectTab === "sitat" &&
                <Form autocomplete="off">
                    <Label htmlFor="sitat">
                        Sitat:
                    </Label>
                    <TextArea
                        name="sitat"
                        value={formState["sitat"]}
                        onChange={(e) => handleFormChange("sitat", e)} />
                    <Label htmlFor="sagtav">
                        Sagt av:
                    </Label>
                    <Text
                        type="text"
                        name="sagtav"
                        value={formState["sagtav"]}
                        onChange={(e) => handleFormChange("sagtav", e)} />
                </Form>
            }
            {props.activeProjectTab === "div" &&
                <Form autocomplete="off">
                    <Label htmlFor="hva">
                        Hva:
                    </Label>
                    <Text
                        type="text"
                        name="hva"
                        value={formState["hva"]}
                        onChange={(e) => handleFormChange("hva", e)} />
                    <Label htmlFor="linkdiv">
                        Link:
                    </Label>
                    <Text
                        type="text"
                        name="linkdiv"
                        value={formState["linkdiv"]}
                        onChange={(e) => handleFormChange("linkdiv", e)} />
                </Form>
            }
            <FileUpload projectId={"testProject"} />
            <ButtonRow>
                <FormButton onClick={handleFormSubmit}>
                    <Icon src={'symboler-15.png'} />
                </FormButton>
                <FormButton onClick={handleFormCancel}>
                    <Icon src={'symboler-16.png'} />
                </FormButton>
            </ButtonRow>
            <BackButton handleClose={handleFormCancel} />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 20%;
`

const TabsContainer = styled.div`
    width: 330px;
    margin-bottom: 75px;
`

const Form = styled.form`
    align-self: flex-start;
    display: grid;
    grid-template-columns: 1fr 3fr;
    width: 100%;
`

const Label = styled.label`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    //align-items: center;
    gap: 5rem;
    font-size: 20pt;
`

const Text = styled.input`
    outline: none;
    border: none;
    margin-left: 4rem;
    color: #D34E38;
    font-size: 20pt;

    background: linear-gradient(to top, #2249AA 2px, transparent 2px) 0 100%;
    background-repeat: no-repeat;
    background-size: 0px 0px;
    transition: background-size 1s cubic-bezier(0, 1.5, 1, 1.5);

    &:hover {
        background-size: 100% 100%;
    }
`

const Select = styled.select`
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    border: none;
    margin-left: 4rem;
    color: #D34E38;
    font-size: 20pt;

    background: linear-gradient(to top, #2249AA 2px, transparent 2px) 0 100%;
    background-repeat: no-repeat;
    background-size: 0px 0px;
    transition: background-size 1s cubic-bezier(0, 1.5, 1, 1.5);

    &:hover {
        background-size: 100% 100%;
    }
`

const Option = styled.option`

`

const TextArea = styled.textarea`
    outline: none;
    border: none;
    height: 15vh;
    resize: vertical;
    margin-left: 4rem;
    color: #D34E38;
    font-size: 20pt;
    font-family: Roboto;

    background: linear-gradient(to bottom, #2249AA 2px, transparent 2px) 0 0, linear-gradient(to right, #2249AA 2px, transparent 2px) 0 100%, linear-gradient(to left, #2249AA 2px, transparent 2px) 100% 0, linear-gradient(to top, #2249AA 2px, transparent 2px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 0px 0px;
    transition: background-size 1s cubic-bezier(0, 1.5, 1, 1.5);

    &:hover {
        background-size: 100% 100%;
    }
`

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 50px;
`

const FormButton = styled.button`
    border: none;
    background: transparent;
`

const Icon = styled.img`
    width: 3rem;
    height: auto;
    cursor: pointer;
`

function mapStateToProps(state) {
    return {
        activeProjectTab: state.activeProjectTab,
        images: state.images,
    }
}

export default connect(mapStateToProps)(AddProject)