const api = process.env.REACT_APP_API_URL; //'http://127.0.0.1:5000/'

var rand = function () {
  return Math.random().toString(36).substr(2); // remove `0.`
};

var newToken = function () {
  return rand() + rand(); // to make it longer
};

var sessionToken = function () {
  let token = localStorage.getItem("sessionToken");
  return token ?? null;
};

export const checkUser = () =>
  fetch(api + `/checkuser`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionToken(),
    },
    body: JSON.stringify(),
  }).then((res) => res.json());

export const login = (v) =>
  fetch(api + `/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: newToken(),
    },
    body: JSON.stringify(v),
  }).then((res) => res.json());

export const getNumberOfProjects = (v) =>
  fetch(api + `/projects`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(v),
  }).then((res) => res.json());

export const getAllProjects = (v) =>
  fetch(api + `/getprojects`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(v),
  }).then((res) => res.json());

export const getAllDivs = (v) =>
  fetch(api + `/getdivs`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(v),
  }).then((res) => res.json());

export const getAllQuotes = (v) =>
  fetch(api + `/getquotes`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(v),
  }).then((res) => res.json());

export const getOneProject = (v) =>
  fetch(api + `/getproject/${v}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionToken(),
    },
    body: JSON.stringify(),
  }).then((res) => res.json());

export const addProject = (v) =>
  fetch(api + `/addproject`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionToken(),
    },
    body: JSON.stringify(v),
  }).then((res) => res.json());

export const addComment = (v) =>
  fetch(api + `/addcomment`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionToken(),
    },
    body: JSON.stringify(v),
  }).then((res) => res.json());

export const addRating = (v) =>
  fetch(api + `/addrating`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionToken(),
    },
    body: JSON.stringify(v),
  }).then((res) => res.json());

export const addDiv = (v) =>
  fetch(api + `/adddiv`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionToken(),
    },
    body: JSON.stringify(v),
  }).then((res) => res.json());

export const addQuote = (v) =>
  fetch(api + `/addquote`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionToken(),
    },
    body: JSON.stringify(v),
  }).then((res) => res.json());

export const editProject = (v) =>
  fetch(api + `/editproject`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionToken(),
    },
    body: JSON.stringify(v),
  }).then((res) => res.json());

export const deleteProject = (v) =>
  fetch(api + `/deleteproject`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionToken(),
    },
    body: JSON.stringify(v),
  }).then((res) => res.json());

export const deleteDiv = (v) =>
  fetch(api + `/deletediv`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: sessionToken(),
    },
    body: JSON.stringify(v),
  }).then((res) => res.json());
