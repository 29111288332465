import { useState, useEffect, useRef } from "react"
import styled, { keyframes } from "styled-components"
import { useDispatch, connect } from "react-redux"
import { useHistory } from 'react-router-dom'
import ArchitechturesGrid from "../components/ArchitecturesGrid"
import BackButton from "../components/BackButton"
import Tabs from "../components/Tabs"
import FaceTabs from "../components/FaceTabs"
import { motion } from "framer-motion"
import { checkUser } from "../fetchApi"

const faces = {
    'Faces-11.png': "FPGL1",
    'Faces-10.png': "FPGL2",
    'Faces-09.png': "FPGL3",
    'Faces-08.png': "FPGL4",
    'Faces-07.png': "FPGL5",
    'Faces-06.png': "FPGL6",
    'Faces-05.png': "FPGL7",
    'Faces-04.png': "FPGL8",
    'Faces-03.png': "FPGL9",
    'Faces-01.png': "FPGL10",
    'Faces-02.png': "FPGL11",
}

const tabs = {
    "Ingen": null,
    "Arkitekt": "architect",
    "Materiale": "material",
    "Størrelse": "size",
    "År": "year",
    "GL": "glrate",
}

const titleLetters = [..."BANK a la FPGL"]

function Dashbaord(props) {
    const [showHalo, setShowHalo] = useState(props.activeFaceIndex)
    const dispatch = useDispatch()
    const history = useHistory()
    const constraintsRef = useRef(null)

    useEffect(() => {
        checkUser().then().catch(_ => {
            localStorage.clear()
            history.push('/login')
        })
    }, [])

    const handleFaceClick = (i, face) => {
        let architectures = props.architectures.slice()
        let divs = props.divs.slice()
        if (face === props.activeFace) {
            dispatch({ type: 'RESET_USER', architectures: architectures, divs: divs })
            setShowHalo(null)
        } else {
            dispatch({ type: 'SET_USER', architectures: architectures.filter(a => a.added_by === face), divs: divs.filter(d => d.added_by === face), face: face, index: i })
            setShowHalo(i)
        }
    }

    const handleAddClick = () => {
        history.push('/add')
    }

    const handleLogOut = () => {
        localStorage.clear()
        history.push('/login')
    }

    return (
        <Wrapper
            initial={false}
            animate={props.showMaterials ? "open" : "closed"}
            ref={constraintsRef}
        >
            <TitleContainer>
                <Title>
                    {titleLetters.map((l, i) => {
                        if (l === " ") {
                            return <span key={i}>{l}</span>
                        } else {
                            return <Letter
                                key={i}
                                drag
                                dragConstraints={constraintsRef}
                                dragTransition={{ bounceStiffness: 300, bounceDamping: 10 }}>
                                {l}
                            </Letter>
                        }
                    })}
                </Title>
                <SubTitle>
                    _et <span style={{ color: '#2249AA' }}>effepechelle</span> samarbeid
                </SubTitle>
            </TitleContainer>
            <FacesContainer>
                {Object.keys(faces).map((f, i) => {
                    let index = i + 1
                    return <div key={index}>
                        {index === showHalo &&
                            <Halo />
                        }
                        <FaceButton key={index} onClick={() => handleFaceClick(index, faces[f])}>
                            <Face src={f} />
                        </FaceButton>
                    </div>
                })}
            </FacesContainer>
            {!showHalo && <Tabs isDashboard tabs={Object.keys(tabs).slice(1, tabs.length)} />}
            {showHalo && <FaceTabs />}
            <ArchitechturesGrid tabs={tabs} />
            <BottomButtonWrapper>
                <AddProjectButton onClick={handleAddClick}>
                    <Plus src={'symboler-17.png'} />
                </AddProjectButton>
            </BottomButtonWrapper>
        </Wrapper>
    )
}

const Wrapper = styled(motion.div)`
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 75px 15rem 0 15rem;

    @media (max-width: 1430px) {
		padding: 75px 10rem 0 10rem;
	}

    @media (max-width: 1275px) {
		padding: 75px 5rem 0 5rem;
	}

    @media (max-width: 925px) {
		padding: 75px 0 0 0;
	}
`

const TitleContainer = styled(Wrapper)`
    padding: 0;
    margin-bottom: 2rem;
`

const Title = styled.div`
    font-size: 60pt;
    font-weight: 500;
    text-align: center;
    
    @media (max-width: 1130px) {
		font-size: 40pt;
	}
`

const Letter = styled(motion.div)`
    display: inline-block;
    z-index: 3;
`

const SubTitle = styled.div`
    font-size: 18pt;
    font-weight: 400;
    font-style: italic;
    text-align: center;
`

const FacesContainer = styled.div`
    z-index: 2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 4rem;
    padding: 0 3rem;
`

const Face = styled.img`
    max-width: 4rem;
    height: auto;
    transition: 100ms linear;
    cursor: pointer;

    &:hover {
        transform: rotate(-10deg);
    }

    @media (max-width: 1425px) {
		max-width: 3rem;
	}
`

const FaceButton = styled.button`
    border: none;
    background: transparent;
`

const Halo = styled.div`
    position: absolute;
    margin-left: 7px;
    width: 50px;
    height: 5px;
    border: 4px solid #D34E38;
    border-radius: 60px / 15px;

    @media (max-width: 1425px) {
		width: 40px;
        height: 5px;
        border: 3px solid #D34E38;
        border-radius: 60px / 15px;
	}
`

const BottomButtonWrapper = styled.div`
    z-index: 2;
    display: flex;
    justify-content: center;
    margin: 0 auto 50px;
`

const AddProjectButton = styled(FaceButton)`
`

const Plus = styled.img`
    width: 3rem;
    height: auto;
    cursor: pointer;
`

const Thing = styled.div`
    position: absolute;
    height: 100%;
    width: 100%;
`

function mapStateToProps(state) {
    return {
        showMaterials: state.activeTab === "Materiale",
        activeMaterial: state.activeMaterial,
        architectures: state.architectures,
        divs: state.divs,
        activeFace: state.activeFace,
        activeFaceIndex: state.activeFaceIndex,
    }
}

export default connect(mapStateToProps)(Dashbaord)