import { useEffect, useState, useRef } from "react"
import { connect, useDispatch } from "react-redux"
import styled from "styled-components"
import { motion } from "framer-motion"
import { useHistory } from 'react-router-dom'
import Loader from './Loader'
import "./border.scss"

const variants = {
    open: { opacity: 0, x: "-150%" },
    closed: { opacity: 1, x: 0 },
}

const sizes = {
    'XS': 0,
    'S': 1,
    'M': 2,
    'L': 3,
    'XL': 4
}

const useDidMountEffect = (func, deps) => {
    const didMount = useRef(false);
    
    useEffect(() => {
        if (didMount.current) func();
        else didMount.current = true;
    }, deps);
}

function ArchitechturesGrid(props) {
    const dispatch = useDispatch()
    const history = useHistory()
    const activeTab = props.tabs[props.activeTab]

    const setData = (data) => {
        dispatch({ type: 'SET_AUGMENTED_ARCHITECTURES', payload: data })
    }

    useDidMountEffect(() => {
        setData(props.userArchitectures.slice())
    }, [props.uuid, props.displayImages, props.divsImages])

    useDidMountEffect(() => {
        let key = activeTab
        let architectures = props.augmentedArchitectures.slice()

        if (key === "name") {
            setData(architectures.sort((a, b) => a[key].localeCompare(b[key])))
        } else if (key !== null && key !== "material") {
            if (key === "size") {
                setData(architectures.sort((a, b) => (sizes[a[key]] < sizes[b[key]]) ? 1 : (sizes[a[key]] === sizes[b[key]]) ? ((a.glrate < b.glrate) ? 1 : -1) : -1))
            } else if (key === "glrate") {
                setData(architectures.sort((a, b) => (a[key] < b[key]) ? 1 : (a[key] === b[key]) ? ((a.glrate < b.glrate) ? 1 : -1) : -1))
            } else if (key === "year") {
                setData(architectures.sort((a, b) => (parseInt(a[key]) > parseInt(b[key])) ? 1 : (parseInt(a[key]) === parseInt(b[key])) ? ((a.glrate < b.glrate) ? 1 : -1) : -1))
            } else {
                setData(architectures.sort((a, b) => (a[key] > b[key]) ? 1 : (a[key] === b[key]) ? ((a.glrate < b.glrate) ? 1 : -1) : -1))
            }
        } else if (key !== "material") {
            setData(props.userArchitectures.slice())
        }
    }, [props.activeTab])

    useDidMountEffect(() => {
        let material = props.activeMaterial
        if (material !== null) {
            let architectures = props.userArchitectures.slice()
            setData(architectures.filter(a => a.material.includes(material.toLowerCase())))
        }
    }, [props.activeMaterial])

    const handleProjectClick = (id) => {
        history.push('/project/' + id)
    }

    const handleDivClick = (id) => {
        history.push('/div/' + id)
    }

    if (props.augmentedArchitectures.length === 0) {
        return (
            <Loader />
        )
    }

    const showArchitectures = props.activeFaceTab === '0'

    return (
        <Grid variants={variants}>
            {showArchitectures
                ?
                <>
                    {
                        props.augmentedArchitectures.map((a, i) => {
                            let imageObject = props.displayImages.filter(i => i.name === "" + a.id)[0]
                            let url = "url"
                            if (imageObject) {
                                url = imageObject.url
                            }
                            return (
                                <Item
                                    key={i}
                                    drag
                                    dragConstraints={{
                                        top: -5,
                                        left: -5,
                                        right: 5,
                                        bottom: 5,
                                    }}
                                    dragTransition={{ bounceStiffness: 600, bounceDamping: 20 }}
                                    dragElastic={0.5}
                                    whileTap={{ cursor: "grabbing" }}
                                >
                                    <div className="project-tile">
                                        <button className="sick-border" onClick={() => handleProjectClick(a.id)}>
                                            <Image src={url} />
                                        </button>
                                        {activeTab ?
                                            <span class="hide">{a[activeTab]}</span>
                                        :
                                            props.activeMaterial && <span class="hide">{a['material']}</span>
                                        }
                                    </div>
                                </Item>
                            )
                        })
                    }
                </>
                :
                <>
                    {
                        props.divs.map((d, i) => {
                            let imageObject = props.divsImages.filter(i => i.name === "" + d.id)[0]
                            let url = "url"
                            if (imageObject) {
                                url = imageObject.url
                            }
                            return (
                                <Item
                                    key={i}
                                    drag
                                    dragConstraints={{
                                        top: -5,
                                        left: -5,
                                        right: 5,
                                        bottom: 5,
                                    }}
                                    dragTransition={{ bounceStiffness: 600, bounceDamping: 20 }}
                                    dragElastic={0.5}
                                    whileTap={{ cursor: "grabbing" }}
                                >
                                    <button className="sick-border" onClick={() => handleDivClick(d.id)}>
                                        <Image src={url} />
                                    </button>
                                </Item>
                            )
                        })
                    }
                </>
            }
        </Grid>
    )
}

const Grid = styled(motion.div)`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    margin-bottom: 50px;

    @media (max-width: 1275px) {
		grid-template-columns: repeat(4, 1fr);
	}
`

const Item = styled(motion.div)`
    display: flex;
    justify-content: center;
    margin: 8px;
`

const BImage = styled.img`
    max-width: 4rem;
    height: auto;

    background: linear-gradient(to bottom, #D34E38 2px, transparent 2px) 0 0, linear-gradient(to right, #D34E38 2px, transparent 2px) 0 100%, linear-gradient(to left, #D34E38 2px, transparent 2px) 100% 0, linear-gradient(to top, #D34E38 2px, transparent 2px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 0px 0px;
    transition: background-size 0.2s cubic-bezier(0, 1.5, 1, 1.5);

    &:hover {
        background-size: 100% 100%;
    }
`

const Image = styled.img`
    padding: 5px;
    height: auto;
    width: 100px;
`

function mapStateToProps(state) {
    return {
        architectures: state.architectures,
        divs: state.userDivs,
        userArchitectures: state.userArchitectures,
        augmentedArchitectures: state.augmentedArchitectures,
        uuid: state.userArchitecturesUuid,
        activeTab: state.activeTab,
        activeMaterial: state.activeMaterial,
        activeFaceTab: state.activeFaceTab,
        displayImages: state.displayImages,
        divsImages: state.divsImages
    }
}

export default connect(mapStateToProps)(ArchitechturesGrid)