import styled, { keyframes } from "styled-components"

export default function Loader(isSmall = false) {

  return (
    <Wrapper isSmall={isSmall}>
      <SickoMode isSmall={isSmall} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 75px 0;

    ${({ isSmall }) => isSmall === false && `
        padding: 0;
    `} 
`

const load = keyframes`
  from {
    background-size: 0px 0px;
  }

  to {
    background-size: 100% 100%;
  }
`;

const SickoMode = styled.div`
    width: 50px;
    height: 50px;
    transform: rotate(45deg);

    ${({ isSmall }) => isSmall === false && `
        width: 30px;
        height: 30px;
    `} 

    background: linear-gradient(to bottom, #2249AA 2px, transparent 2px) 0 0, linear-gradient(to right, #2249AA 2px, transparent 2px) 0 100%, linear-gradient(to left, #2249AA 2px, transparent 2px) 100% 0, linear-gradient(to top, #2249AA 2px, transparent 2px) 100% 100%;
    /* transition: background-size 0.2s cubic-bezier(0, 1.5, 1, 1.5); */
    background-repeat: no-repeat;
    animation-timing-function: ease-in-out;
    animation-duration: 0.8s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-name: ${load};
`