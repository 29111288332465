import styled from "styled-components"
import { useDispatch } from "react-redux"
import { connect } from "react-redux"

function ProjectTab(props) {
    const dispatch = useDispatch()

    const handleTabClick = (tab) => {
        dispatch({ type: 'SET_ACTIVE_PROJECT_TAB', payload: tab })
    }

    return (
        <div>
            <Button onClick={() => handleTabClick(props.name)}>{props.name}</Button>
        </div>
    )
}

const Button = styled.button`
    border: none;
    background-color: white;
    font-size: 20pt;
    font-family: Roboto;
    font-weight: 500;
    color: #D34E38;
    cursor: pointer;
    transition: text-shadow 100ms ease-in;

    &:hover {
        text-shadow: 2px 2px #FF0000;
        color: #2249AA;
    }
`

function mapStateToProps(state) {
    return {
        activeTab: state.activeProjectTab,
    }
}

export default connect(mapStateToProps)(ProjectTab)