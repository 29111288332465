import styled, { keyframes } from 'styled-components'

function BackButton(props) {

    return (
        <Button onClick={props.handleClose}>
            <Image src={'/symboler-18.png'} />
        </Button>
    )
}

const wiggle = keyframes`
    0% {
        transform: rotate(-10deg);
    }
    33% {
        transform: rotate(30deg);
    }
    67% {
        transform: rotate(-20deg);
    }
    100% {
        transform: rotate(0deg);
    }
`

const Button = styled.button`
    z-index: 2;
    position: absolute;
    border: none;
    background: none;
    top: 75px;
    right: 15%;
    cursor: pointer;

    &:hover {
        animation: ${wiggle} 300ms linear;
    }
`

const Image = styled.img`
    height: 55px;
    width: 65px;
`


export default BackButton