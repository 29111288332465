import styled from 'styled-components'

function FieldSet({ type, name, fieldText, handleInputChange, handleKeyDown, value }) {

    return (
        <Field>
            {fieldText &&
                <Text>{fieldText}</Text>
            }
            <Input
                type={type}
                name={name}
                value={value}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                autocomplete="off" 
            />
        </Field>
    )
}

const Field = styled.fieldset`
	display: flex;
    flex-direction: row;
    border: none;
    justify-content: space-between;
`

const Input = styled.input`
    border: none;
    border-bottom: 1px solid #D34E38;
	font-size: 24pt;
    font-weight: 500;
    width: 350px;
    justify-self: flex-end;
	color: #D34E38;
    
	&:focus {
        outline: none;
	}
    
    ${({ name }) => name === 'project' && `
        font-size: 16pt;
        width: 100%;
    `}
`

const Text = styled.label`
	font-size: 24pt;
    font-weight: 500;
	color: #D34E38;
`

export default FieldSet