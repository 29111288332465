import firebase from 'firebase/compat/app'
import 'firebase/compat/storage'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAO31IK0zsrxaOqZdB-JKwLA9WuiDHgBIk",
    authDomain: "archi-base-front.firebaseapp.com",
    projectId: "archi-base-front",
    storageBucket: "archi-base-front.appspot.com",
    messagingSenderId: "886683546173",
    appId: "1:886683546173:web:94531be05a47b9fab17054",
    measurementId: "G-N76ZPZ76ZJ"
}

firebase.initializeApp(firebaseConfig)

const storage = firebase.storage()

export {storage, firebase as default}