import { v4 as uuidv4 } from 'uuid'

const baseState = {
  architectures: [],
  userArchitectures: [],
  augmentedArchitectures: [],
  divs: [],
  userDivs: [],
  quotes: [],
  activeTab: 'Ingen',
  activeFaceTab: '0',
  activeProjectTab: 'prosjekt',
  activeMaterial: null,
  activeFace: null,
  activeFaceIndex: null,
  userArchitecturesUuid: uuidv4(),
  constraintsRef: null,
  images: [],
  displayImages: [],
  divsImages: [],
  materials: []
}

export default function reducer(
  state = {
    ...baseState
  },
  action
) {
  switch (action.type) {
    case 'ADD_ARCHITECTURES':
      return {
        ...state,
        architectures: action.payload,
        userArchitectures: action.payload,
        augmentedArchitectures: action.payload,
        materials: [...new Set(action.payload.map(a => a.material.split(',').map(m => m.trim())).flat()), 'alle'],
        userArchitecturesUuid: uuidv4(),
        constraintsRef: action.ref,
        activeTab: 'Ingen',
        activeFaceTab: '0',
        activeMaterial: null,
        activeFace: null,
        activeFaceIndex: null,
        images: [],
      }
    case 'ADD_DIVS':
      return {
        ...state,
        divs: action.payload,
        activeTab: 'Ingen',
        activeFaceTab: '0',
        activeMaterial: null,
        activeFace: null,
        activeFaceIndex: null,
        images: [],
      }
    case 'SET_USER':
      return {
        ...state,
        userArchitectures: action.architectures,
        userArchitecturesUuid: uuidv4(),
        userDivs: action.divs,
        activeFace: action.face,
        activeFaceIndex: action.index,
        activeTab: 'Ingen',
        activeMaterial: null
      }
    case 'RESET_USER':
      return {
        ...state,
        userArchitectures: action.architectures,
        userArchitecturesUuid: uuidv4(),
        userDivs: action.divs,
        activeFace: null,
        activeFaceTab: '0',
        activeFaceIndex: null,
        activeTab: 'Ingen',
        activeMaterial: null
      }
    case 'SET_AUGMENTED_ARCHITECTURES':
      return {
        ...state,
        augmentedArchitectures: action.payload
      }
    case 'SET_ACTIVE_TAB':
      return {
        ...state,
        activeTab: action.payload
      }
    case 'SET_ACTIVE_FACE_TAB':
      return {
        ...state,
        activeFaceTab: action.payload
      }
    case 'SET_ACTIVE_MATERIAL':
      return {
        ...state,
        activeMaterial: action.payload,
        activeTab: 'Ingen'
      }
    case 'RESET_GRID':
      return {
        ...state,
        activeTab: 'Ingen',
        activeMaterial: null
      }
    case 'SET_ACTIVE_PROJECT_TAB':
      return {
        ...state,
        activeProjectTab: action.payload,
        images: []
      }
    case 'ADD_IMAGES':
      return {
        ...state,
        images: [...state.images, ...action.payload]
      }
    case 'ADD_DISPLAY_IMAGES':
      return {
        ...state,
        displayImages: [...state.displayImages, action.payload]
      }
    case 'ADD_DIVS_IMAGES':
      return {
        ...state,
        divsImages: [...state.divsImages, action.payload]
      }
    default:
      return state
  }
}