import styled from "styled-components"
import { useDispatch } from "react-redux"
import { connect } from "react-redux"
import { motion } from "framer-motion"

const materials = [
    "Tre",
    "Stein",
    "Tegl",
    "Stål",
    "Papir",
    "Annet",
    "Alle",
]

const ul_variants = {
    open: {
        transition: { staggerChildren: 0.07, delayChildren: 0 }
    },
    closed: {
        transition: { staggerChildren: 0.05, staggerDirection: -1 }
    }
}

const li_variants = {
    open: {
        y: 0,
        opacity: 1,
        transition: {
            y: { stiffness: 1000, velocity: -100 }
        }
    },
    closed: {
        y: 30,
        opacity: 0,
        transition: {
            y: { stiffness: 1000 }
        }
    }
}

function Tab(props) {
    const dispatch = useDispatch()

    const handleTabClick = (tab) => {
        if (tab === props.activeTab) {
            dispatch({ type: 'RESET_GRID' })
        } else {
            dispatch({ type: 'SET_ACTIVE_TAB', payload: props.name })
        }
    }

    const materials = props.materials
    const noActiveMaterial = props.activeMaterial === null
    return (
        <div>
            <Button onClick={() => handleTabClick(props.name)} blue={!noActiveMaterial}>{props.name !== 'Materiale' ? props.name : noActiveMaterial ? props.name : props.activeMaterial[0].toUpperCase() + props.activeMaterial.slice(1)}</Button>
            {(props.name === "Materiale" && props.activeTab === props.name) &&
                <List variants={ul_variants}>
                    {materials.map((m, i) => (
                        <ListItem key={i}
                            onClick={() => dispatch({ type: 'SET_ACTIVE_MATERIAL', payload: m === "alle" || m === props.activeMaterial ? null : m })}
                            variants={li_variants}
                            whileHover={{ translateX: "-10px" }}
                        >

                            {m}
                        </ListItem>
                    ))}
                </List>
            }
        </div>
    )
}

const Button = styled.button`
    border: none;
    background: transparent;
    font-size: 20pt;
    font-family: Roboto;
    font-weight: 500;
    color: #D34E38;
    cursor: pointer;
    transition: text-shadow 100ms ease-in;

    ${({ blue }) => blue === true && `
        color: #2249AA;
    `} 

    &:hover {
        text-shadow: 2px 2px #FF0000;
        color: #2249AA;
    }
`

const List = styled(motion.ul)`
    list-style: none;
    padding-left: 1.5rem;
    font-size: 20pt;
`

const ListItem = styled(motion.li)`
    color: #2249AA;
    &:hover {
        cursor: pointer;
    }
`

function mapStateToProps(state) {
    return {
        activeTab: state.activeTab,
        activeMaterial: state.activeMaterial,
        materials: state.materials
    }
}

export default connect(mapStateToProps)(Tab)